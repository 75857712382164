import React from "react"
import Layout from "../../components/layout/layout"
import SEO from "../../components/seo"
import TopHeroComponent from "../../components/herocomponents/topherocomponent"
import PartnersCompany from "../../components/bodycomponents/partnerscompany"
import LabOPS from "../../images/about-us/partners/LabOPS.png"
import MassBio from "../../images/about-us/partners/massbio.png"
import MTC from "../../images/about-us/partners/MTClogo.jpg"
import NCLife from "../../images/about-us/partners/nclifesci.png"
import R_BLUE_BG from "../../images/background-images/bg-royal-blue.svg"
import { colors } from "../../utils/colors"

export default function PartnersPage() {
  return (
    <>
      <Layout>
        <SEO
          title={`Our Partners | Collaborating with Surplus Solutions`}
          description={`Discover our trusted partners who help us deliver exceptional services in equipment appraisals, logistics, and relocations.`}
        />
        <TopHeroComponent
          backgroundImage={R_BLUE_BG}
          backgroundColor={colors.blue}
          fontShadowColor={colors.royalBlue}
          shadowColor="shadow-royalBlue-bottom"
          pageTitle="Our Partners"
          title="Advancing Life Sciences Through Strategic Partnerships"
          body={
            <p className="pt-4">
              Surplus Solutions fosters innovation in healthcare and science
              through partnerships with MassBio, NC Life Sciences Organization, Maryland Tech
              Council, and LabOps Unite. These collaborations drive industry
              growth, enhancing innovation and benefiting diverse communities.
            </p>
          }
        />
        <div className="flex flex-col items-stretch shadow-custom-out">
          <PartnersCompany
            backgroundColor={colors.white}
            link="https://www.massbio.org/"
            imageSource={MassBio}
            imgAlt="MassBio Logo"
            title="MassBio"
            body={
              <p>
                Through our partnership with
                <a
                  href="https://www.massbio.org/"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  MassBio
                </a>
                , we engage with a dynamic network of biotech organizations
                across Massachusetts. This collaboration enables us to
                contribute to initiatives that drive biotech growth and foster
                scientific innovation. Our involvement supports the development
                of life-saving technologies and improved healthcare solutions.
              </p>
            }
          />

          <PartnersCompany
            backgroundColor={colors.lightGray}
            link="https://www.nclifesci.org/"
            imageSource={NCLife}
            imgAlt="NC Life Science Logo"
            title="NC Life Sciences Organization"
            body={
              <p>
                As a proud sponsor of{" "}
                <a
                  href="https://www.nclifesci.org/"
                  target="_blank"
                  rel="noreferrer"
                >
                  NC Life Sciences Organization
                </a>
                , we actively contribute to North Carolina’s thriving life
                sciences ecosystem. By supporting local innovation and
                industry-focused events, we help strengthen the region’s
                position as a leader in the biotech sector, ultimately
                benefiting healthcare advancements and patient outcomes.
              </p>
            }
          />
          <PartnersCompany
            backgroundColor={colors.white}
            link="https://mdtechcouncil.com/"
            imageSource={MTC}
            imgAlt="Maryland Tech Council Logo"
            title="Maryland Tech Council"
            body={
              <p>
                Our partnership with the{" "}
                <a
                  href="https://mdtechcouncil.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Maryland Tech Council (MTC)
                </a>
                &nbsp;allows us to engage with both tech and life sciences
                leaders across the state. This collaboration enhances our
                ability to support industry growth and advocacy efforts,
                ensuring a better future for healthcare solutions throughout
                Maryland.
              </p>
            }
          />
          <PartnersCompany
            backgroundColor={colors.lightGray}
            link="https://labopsunite.org/"
            imageSource={LabOPS}
            imgAlt="LabOpsUnite Logo"
            title="LabOps Unite"
            body={
              <p>
                As a partner of{" "}
                <a
                  href="https://labopsunite.org/"
                  target="_blank"
                  rel="noreferrer"
                >
                  LabOps Unite
                </a>
                , we’re dedicated to improving lab operations through supporting
                training, resource sharing, and advocacy. Our support helps
                drive best practices in lab management, professional
                development, and operational efficiency, ensuring labs across
                the industry can optimize performance.
              </p>
            }
          />
        </div>
      </Layout>
    </>
  )
}
