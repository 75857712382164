import React from "react"

export default function PartnersCompany(props) {
  return (
    <div
      className="h-full"
      style={{
        backgroundColor: props.backgroundColor,
      }}
    >
      <div className="container max-w-screen-xl flex flex-col md:flex-row py-14">
        <div className="w-full md:w-2/5 pb-5">
          <a href={props.link} target="_blank" rel="noreferrer" className="block px-4">
            <img
              src={props.imageSource}
              alt={props.imageAlt}
              className="aspect-[3/2] object-contain w-full"
            />
          </a>
        </div>

        <div className="flex flex-col w-full px-6">
          <h3 className="mb-0 pb-4">{props.title}</h3>
          <span className="pb-5">{props.body}</span>
        </div>
      </div>
    </div>
  )
}
